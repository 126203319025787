export const protocol = process.env.REACT_APP_PROTOCOL;
export const domain = process.env.REACT_APP_WS_URL;
// export const domain = 'https://031f-2405-201-5c1a-4857-194d-a839-433b-4c15.ngrok-free.app';
export const port = process.env.REACT_APP_PORT;
export const reactport = process.env.REACT_APP_REACTPORT;

export const reactcontext = process.env.REACT_APP_REACTCONTEXT;
export const apicontext = process.env.REACT_APP_APICONTEXT;
export const cookiedomain = process.env.REACT_APP_COOKIEDOMAIN;

export const restbaseurl = process.env.REACT_APP_RESTBASEURL;
// export const restbaseurl = 'https://031f-2405-201-5c1a-4857-194d-a839-433b-4c15.ngrok-free.app';
export const cdnurl = process.env.REACT_APP_CDNURL;
export const uploadsContext = process.env.REACT_APP_UPLOADSCONTEXT;
export const contexPath = process.env.REACT_APP_CONTEXPATH;
export const timerRefreshInterval = 120000;

