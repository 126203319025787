/* eslint-disable react/jsx-props-no-spreading */
import Cookies from "js-cookie";
// import UserInfo from 'models/UserInfo';
// import GlobalService from "../services/GlobalServices";
// import { resturls } from "./apiurls";
import { cookiedomain } from "./constants";
import ls from "local-storage";
// import iocLogo from 'assets/images/favIconIoc.svg';

function updateUserInfo(token = null, role = 'normal', userId, businesses) {
  let val1 = token;
  const ck = Cookies.get("access_token", { domain: cookiedomain });  
  if (ck != null) {
    val1 = ck;
    const now = new Date();
    const cookieObj = {
      data: val1,
      expiry: now.getTime() + 1209600000,
      role,
      userId,
      businesses
    };
    ls.set("access_token", cookieObj);
    console.log("Thru cookie");
  } else if (token !== undefined && token !== null) {
    const now = new Date();
    const cookieObj = {
      data: token,
      expiry: now.getTime() + 1209600000,
      role,
      userId,
      businesses
    };
    ls.set("access_token", cookieObj);
    console.log("Thru Params - post login", cookieObj);
  }
  if (val1 === undefined || val1 === null) {
    const cookieObj = ls.get("access_token");
    if (cookieObj !== undefined && cookieObj !== null) {
      const now = new Date();
      if (now.getTime() <= cookieObj.expiry) {
        val1 = cookieObj.data;
        cookieObj.expiry = now.getTime() + 1209600000;
        cookieObj.role = role;
        cookieObj.userId = userId;
        cookieObj.businesses = businesses;
        ls.set("access_token", cookieObj);
      } else {
        ls.remove("access_token");
        ls.remove("userName");
      }
    }
    console.log("Thru Local Store");
  }

}

function clearCookies() {
  let ck = Cookies.get("access_token", { domain: cookiedomain });
  console.log("before logout ck", ck, cookiedomain);
  Cookies.remove("access_token", { domain: cookiedomain });
  ck = Cookies.get("access_token", { domain: cookiedomain });
  ls.remove("access_token");
  ls.remove("userName");
  document.cookie = "access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.location.href = "/login";
}

function processLogout(redirectToLogin = true) {
          // let ck = Cookies.get("access_token", { domain: cookiedomain });
          Cookies.remove("access_token", { domain: cookiedomain });
          // ck = Cookies.get("access_token", { domain: cookiedomain });
          ls.remove("access_token");
          ls.remove("userName");
          ls.remove("selectedBusiness");
          ls.remove("recentSearches");
          document.cookie =
            "access_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          if (redirectToLogin) {
            document.location.href = "/login";
          }
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("loggedOut");
          }
        
}

export {
  updateUserInfo,
  processLogout,
  clearCookies,
};
