import React, { createContext, useContext, useEffect, useState } from 'react';
import ls from 'local-storage';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalService from "../componets/services/GlobalServices";
import { resturls } from "../componets/utils/apiurls";
import { domain } from "../componets/utils/constants";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [role, setRole] = useState("normal");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [headerLogo, setLogo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [businessList, setBusiness] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);

  const navigate = useNavigate();
  const location = useLocation();

  const publicRoutes = ["/forgetPassword", "/login", "/unauthorized", "/500"];

  useEffect(() => {
    // Ask for notification permission on mount
    if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        setNotificationPermission(permission);
        if (permission === 'granted') {
          console.log('Notification permission granted');
        } else if (permission === 'denied') {
          console.log('Notification permission denied');
        }
      });
    } else if (Notification.permission === 'granted') {
      console.log('Notification permission already granted');
    } else {
      console.log('Notification permission already denied');
    }
  }, []);
  

  const handleTokenInvalidation = () => {
    ls.remove("access_token");
    setIsAuthenticated(false);
    if (location.pathname !== "/login") navigate("/login");
  };

  const token = ls.get("access_token")?.data;
  useEffect(() => {
    if (!token) {
      console.error("Access token is missing.");
      return;
    }

    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    console.log("WebSocket protocol:", wsProtocol);
    const wsUrl = `${wsProtocol}://${domain}/ws/notifications/?token=${token}`;

    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    socket.onopen = () => console.log("WebSocket connected.");
    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
    
        if (Array.isArray(data.notifications)) {
          // If notifications are an array
          setNotifications((prevNotifications) => [...data.notifications, ...prevNotifications]);
        } else if (data.notifications) {
          // If notifications is a single object (legacy handling)
          setNotifications((prevNotifications) => [data.notifications, ...prevNotifications]);
          setHasNewNotification(true);
          if(notificationPermission){
            new Notification("New notification", {
              body: `${data.message}`,
            });
          }
        } else {
          // Handle when data is directly a single notification object
          setNotifications((prevNotifications) => [data, ...prevNotifications]);
          if(notificationPermission){
            new Notification("New notification", {
              body: `${data.message}`,
            });
          }
          setHasNewNotification(true);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };
    
    
    
    socket.onerror = (error) => console.error("WebSocket error:", error.message);
    socket.onclose = (event) =>
      console.log(`WebSocket closed: ${event.reason || "Connection closed."}`);

    // Cleanup on component unmount
    return () => {
      console.log("Closing WebSocket connection.");
      socket.close();
    };
  }, [token]);


  console.log(notifications, "notifications------>");

  const checkTokenValidity = (obj) => {
    GlobalService.generalSelect(
      (respdata) => {
        const { msg } = respdata;
        if (msg === "success") {
          setIsAuthenticated(true);
        } else {
          handleTokenInvalidation();
        }
      },
      resturls.checkTokenValidity,
      obj,
      "POST"
    );
  };

  const checkTokenExpiry = (call) => {
    setIsLoading(true);
    const now = new Date();
    const cookieObj = ls.get("access_token");
    const obj = { token: cookieObj?.data };

    console.log(cookieObj, "cookieObj");

    if (cookieObj) {
      if (call) {
        checkTokenValidity(obj);
      } else {
        if (now.getTime() <= cookieObj.expiry) {
          setIsAuthenticated(true);
          setUserInfo(cookieObj);
        } else {
          ls.remove("access_token");
          setIsAuthenticated(false);
          if (location.pathname !== "/login") navigate("/login");
        }
      }
    } else {
      setIsAuthenticated(false);
      if (location.pathname !== "/login") navigate("/login");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkTokenExpiry();
    const interval = setInterval(() => {
      checkTokenExpiry(true);
    }, 3600000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        role,
        notifications,
        setRole,
        setBusiness,
        businessList,
        isAuthenticated,
        setIsAuthenticated,
        setLogo,
        headerLogo,
        isLoading,
        userInfo,
        setUserInfo,
        setHasNewNotification, 
        hasNewNotification,
        setNotifications,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
